import React from 'react';
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const ProductsCard = ({ data }) => {
    const mainCarouselItems = data && data.map(((cardData, index) => (

        <div key={index} className="flex justify-center items-center font-[inter] mx-3">
            <div className="p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md min-w-[280px] xl:h-[600px] lg:h-[600px] md:h-[300px]  h-[290px] flex flex-col items-center justify-between border-2 border-[#F3755B]">
                <img
                    src={cardData.image}
                    alt="Card Image"
                    className=" xl:w-[320px] lg:w-[320px] md:w-[100px] w-[100px] xl:h-[320px] lg:h-[320px] md:h-[320px] h-[100px] rounded-lg mb-4"
                />
                <h2 className="text-xl font-semibold mb-2 text-center">{cardData.CardHeading}</h2>
                <p className="text-center mb-4 flex-grow">
                    {cardData.content}
                </p>
                <button className="bg-[#FF5E14] text-white py-2 px-4 rounded hover:bg-[#e64a43] focus:outline-none focus:ring-2 focus:ring-[#e64a43]">
                    Know More
                </button>
            </div>
        </div>
    )));
    return (

        <AliceCarousel
            mouseTracking
            items={mainCarouselItems}
            autoPlay
            infinite
            autoPlayInterval={2000}
            // disableDotsControls
            disableButtonsControls
            responsive={{
                0: { items: 1 },
                768: { items: 2 },
                1024: { items: 3 },
                1280: { items: 3 },
            }}
        />
    );
};

export default ProductsCard;

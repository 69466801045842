import React from "react";


function PaymentPlatform() {
  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center py-[24px] sm:py-[80px] lg:py-[100px] px-4 sm:px-6 lg:px-8">
          <p className="text-[24px] sm:text-[32px] lg:text-[42px] xl:text-[45px] max-w-[826px] text-center font-semibold ">
            Power your business with the ultimate payments platform.
          </p>
          <p className="text-xs sm:text-base lg:text-lg xl:text-xl max-w-[800px] xl:text-[17px] text-center mt-[22px]">
            The AndPos N82 is a smart POS terminal with secure and stable
            performance. It runs the Android 10 operating system and has a
            quad-core processor, providing a premium experience with a large
            battery. Its slim and light design makes it portable.
          </p>
        </div>
        <div className="container mx-auto lg:px-10 xl:mb-20 lg:mb-20 mb-10">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div className="xl:grid lg:grid md:flex grid grid-cols-2 gap-[40px] sm:gap-[60px] lg:gap-[60px] xl:gap-[100px] mb-8 lg:mb-0">
              <div className="text-center">
                <div className="border-4 rounded-full xl:w-[130px] lg:w-[100px] md:w-[100px] w-[60px]  sm:w-[110px] sm:h-[110px] lg:w-[130px] lg:h-[130px] xl:w-[130px] xl:h-[130px] lg:h-[130px] md:h-[100px] h-[60px] flex justify-center items-center mx-auto">
                  <img
                    src="../../../../assets/Images/performanceMeter.svg"
                    className="xl:w-[60px] lg:w-[60px] md:w-[40px] w-[30px] xl:h-[60px] lg:h-[60px] md:h-[40px] h-[30px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Performance
                </p>
              </div>
              <div className="text-center">
                <div className="border-4 rounded-full xl:w-[130px] lg:w-[100px] md:w-[100px] w-[60px]  sm:w-[110px] sm:h-[110px] lg:w-[130px] lg:h-[130px] xl:w-[130px] xl:h-[130px] lg:h-[130px] md:h-[100px] h-[60px] flex justify-center items-center mx-auto">
                  <img
                    src="../../../../assets/Images/portable.svg"
                    className="xl:w-[60px] lg:w-[60px] md:w-[40px] w-[30px] xl:h-[60px] lg:h-[60px] md:h-[40px] h-[30px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Portable
                </p>
              </div>
              <div className="text-center">
                <div className="border-4 rounded-full xl:w-[130px] lg:w-[100px] md:w-[100px] w-[60px]  sm:w-[110px] sm:h-[110px] lg:w-[130px] lg:h-[130px] xl:w-[130px] xl:h-[130px] lg:h-[130px] md:h-[100px] h-[60px] flex justify-center items-center mx-auto">
                  <img
                    src="../../../../assets/Images/battery.svg"
                    className="xl:w-[60px] lg:w-[60px] md:w-[40px] w-[30px] xl:h-[60px] lg:h-[60px] md:h-[40px] h-[30px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Power-Efficient
                </p>
              </div>
              <div className="text-center">
                <div className="border-4 rounded-full xl:w-[130px] lg:w-[100px] md:w-[100px] w-[60px]  sm:w-[110px] sm:h-[110px] lg:w-[130px] lg:h-[130px] xl:w-[130px] xl:h-[130px] lg:h-[130px] md:h-[100px] h-[60px] flex justify-center items-center mx-auto">
                  <img
                    src="../../../../assets/Images/right.svg"
                    className="xl:w-[60px] lg:w-[60px] md:w-[40px] w-[30px] xl:h-[60px] lg:h-[60px] md:h-[40px] h-[30px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Sm Day Settlement
                </p>
              </div>
            </div>

            <div className="w-[180px] h-auto sm:w-[250px] lg:w-[550px] xl:w-[35%] xl:h-auto max-w-full">
              <img
                src="../../../../assets/Images/c20 SE.svg"
                className=""
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPlatform;

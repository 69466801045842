import React from "react";

// Make sure to create and include this CSS file

function HeroSection() {


  return (
    <>
      <div className="overflow-hidden">

      <div className='bg-custom-image md:relative xl:py-[110px] lg:py-[110px] md:py-[50px] py-[30px] xl:px-0 lg:px-0 md:px-[20px] px-[20px] xl:h-[70vh] lg:h-[70vh] md:h-[50vh]'>
                <div className='container mx-auto'>
                  <div className="flex ">
                    <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]">
                      <div className=" xl:max-w-[500x] lg:max-w-[500x] md:max-w-[500x] max-w-[100%]">
                        <h1 className="xl:text-[54px] lg:text-[54px] md:text-[34px] text-[24px] text-[#FF5E14] font-bold">
                          Introducing AndPOS
                        </h1>
                        <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10">
                          <span className="text-[#FF5E14]">Smart POS</span> Terminal <br/>
                          Slim, Portable and Fashionable
                        </p>
              
                        
                        <p className="text-sm sm:text-base lg:text-lg xl:text-xl text-[#777777] pt-7">
                          Go with our Merchant Acquiring Solutions Let payments <br/> technology grow your world.
                        </p>
                       
                      </div>
                    </div>
                    <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] hidden">
                      <img className='mx-auto w-[70%] hidden' src="../../../../../assets/Images/p30.png" alt="product img"></img>
                    </div>
                  </div>
                </div>
              </div>
   
      </div>

    </>
  );
}

export default HeroSection;

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Contact from './pages/Contact';
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './pages/home/Home';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './common/ScrollToTop';
import Productsheet from './pages/products/ecrproductpage/Productsheet';
import Herosection from './pages/products/productcse/Herosection';
import ProductPage from './pages/products/ProductPage';
import Bannersection from './pages/products/Cxproducts/Bannersection';
import Slidersection from './pages/products/Ltwentyproducts/Slidersection';
import Pbanner from './pages/products/Ptwentyproducts/Pbanner';
import Unleash from './pages/products/Pthirtyproducts/Unleash';

function App() {
  const [loacldata, setLocaldata] = useState(false)
  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Header isOpen={setLocaldata} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact open={loacldata} />} />
        <Route path="/Products" element={<ProductPage />} />
        <Route path="/ecr" element={<Productsheet />} />
        <Route path="/c20se" element={<Herosection />} />
        <Route path="/Cxproducts" element={<Bannersection />} />
        <Route path="/Ltwentyproducts" element={<Slidersection />} />
        <Route path="/Ptwentyproducts" element={<Pbanner />} />
        <Route path="/Pthirtyproducts" element={<Unleash />} />
      </Routes>
      <Footer />
    </Router>
  );  
}

export default App;

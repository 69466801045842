import React from 'react'
import Testimonial from '../testimonial/Testimonial';
import HeroSection from "../../Component/HeroSection/HeroSection";
import PaymentPlatform from "../../Component/PaymentPlatform/PaymentPlatform";
import ProductSection from "../../Component/ProductSection/ProductSection"
import AboutUs from '../../Component/AboutUs';
import OurProducts from '../../Component/OurProducts';
import ProductFeatures from '../../Component/ProductFeatures';
import Clitefeature from '../products/Ltwentyproducts/Clitefeature';


function Home() {
    return (
        <div>
            <HeroSection />
            <PaymentPlatform />
            <ProductSection />
            <ProductFeatures />
            <OurProducts />
            <AboutUs />
            <Testimonial />
        </div>
    )
}

export default Home
import ProductsCard from "../common/ProductsCard";
const OurProducts = () => {
  const cardData = [
    {
      image: "../../../assets/Images/c20 lite.svg",
      CardHeading: "AndPos C20 Lite",
      content:
        "Elevate user experience to new heights with futuristic terminal.",
    },
    {
      image: "../../../assets/Images/c20 pro 111.svg",
      CardHeading: "AndPos C20 SE",
      content: "Unleash your terminal's potential with unshakeable support",
    },
    {
      image: "../../../assets/Images/andpos.svg",
      CardHeading: "AndPos Cx20 Windows",
      content: "Windows Powered ECR Terminal Efficiency Meets Compatibility",
    },
    {
      image: "../../../assets/Images/c20 SE.svg",
      CardHeading: "AndPos C20 Pro",
      content:
        "Elevate user experience to new heights with futuristic terminal.",
    },
    {
      image: "../../../assets/Images/cx20.svg",
      CardHeading: "AndPos Cx20",
      content: "Windows Powered ECR Terminal Efficiency Meets Compatibility",
    },

  ];

  return (
    <div
      className="container mx-auto w-full h-full lg:pb-16 lg:my-5"
      id="product"
    >
      <section className="w-4/5 mx-auto font-[inter]">
        <h3 className="xl:text-[42px] lg:text-[42px]  text-[30px] md:text-[48px] font-semibold block text-center">
          Our Products
        </h3>
        <p className="text-center text-xs md:text-lg">
          Choose the terminal option that works for you
        </p>
        <div className="md:gap-8 sm:gap-12 pt-12 md:pt-14 " >
          <ProductsCard data={cardData}/>
        </div>
      </section>
    </div>
  );
};

export default OurProducts;
